// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentServicingShared } from '../../../../../libs/env/src/index';

export const env: EnvironmentServicingShared = {
  static: {
    applicationName: 'ecp-ui-servicing-consumer',
    authKey: '8f920872-9dee-4f63-a8c2-d051cf82d08f',
    baseExp: 'Servicing',
  },
};
