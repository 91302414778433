import type { EnvironmentServicingShared } from './types';

/** Shared across servicing applications and all runtime environments. */
export const env: EnvironmentServicingShared = {
  static: {
    sourceId: 'ecp-polaris',
  },
  afeUserAuthRedirectUri: 'oktaauthn/v1/login/callback',
  ccpaUrl:
    'https://privacyportal.onetrust.com/webform/ef930062-cfb5-4b42-b987-b0fac3955361/ebfcda09-66e8-4da8-ac14-c240555039f9',
  datadogApplicationId: 'f50074ad-e3b7-4630-883e-99686ff86ce3',
  datadogClientToken: 'pub34e3a957ebdeeba9515a907db13bcb57',
  datadogService: 'ecp-ui-servicing',
  gtmId: 'GTM-PQ6PR64',
  hotjarEnabled: false,
  optimizely: {
    proxyEnabled: true,
  },
} as const;
