import { merge } from '@ecp/utils/common';

import { env as baseEnv } from './env';
import type { EnvironmentShared } from './types';

/**
 * Shared across all applications in qa runtime environment.
 * Extends base env.
 */
export const env: EnvironmentShared = merge<
  EnvironmentShared,
  EnvironmentShared,
  EnvironmentShared
>({}, baseEnv, {
  runtimeEnv: 'qa',
  configApiRoot: 'https://configservice.partner-qa.homesitep2.com/v1/config-service/config',
});
