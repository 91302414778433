import type { EnvironmentShared } from './types';

/** Shared across all applications and all runtime environments. */
export const env: EnvironmentShared = {
  static: {
    releaseTimestamp: new Date().getTime(),
    releaseVersion: '0.0.0',
    nodeEnv: 'production',
    isAgent: false,
  },
  chatEnabled: false,
  gtmEnabled: true,
  datadogSessionReplayEnabled: true,
  hotjarEnabled: false,
  hotjarSnippetVersion: '6',
  optimizely: {
    enabled: true,
    testAudience: false,
  },
  smartyStreetsAutoCompleteApiRoot: 'https://us-autocomplete-pro.api.smartystreets.com',
  smartyStreetsStreetApiRoot: 'https://us-street.api.smartystreets.com',
  smartyStreetsZipCodeApiRoot: 'https://us-zipcode.api.smartystreets.com',
  smartyStreetsApiAuthId: '4955221108902814',
  // Keeping the reference to fuelApi to update it later when we call new api
  vehicleImageApiRoot: 'https://api.fuelapi.com/v1/json',
  vehicleImageApiKey: 'fff2620e-ea39-4cdb-ada1-de678c03a767',
};
