import { merge } from '@ecp/utils/common';

import { env as baseEnv } from '../shared/env.qa';
import { env as baseEnvServicing } from './env';
import type { EnvironmentServicingShared } from './types';

/** DON'T USE IN ANY LIBRARY MODULES. TO BE IMPORTED IN APP ENV MODULES ONLY. */
export const env: EnvironmentServicingShared = merge<
  EnvironmentServicingShared,
  EnvironmentServicingShared,
  EnvironmentServicingShared,
  EnvironmentServicingShared
>({}, baseEnv, baseEnvServicing, {
  afeApiKey: 'qyql3G2iLXwQQQj1Vln9moE7WUVEAy2oCS0pPApwJ89ztcMI',
  creditCardTokenServiceUrl: 'https://qa-cctokengenerator.homesitep2.com/v1/creditcard/token',
  legacyPolicyServiceUrl: 'https://quickservice-test.connectbyamfam.com/polaris',
  servicingApiRoot: 'https://api-qa.af1platform.com',
  servicingClientId: '0oa4jr77ssh3hJMLe1d7',
  azureClientId: '65558a15-c4ee-4afd-8b0f-58fa9f47d470',
  agentToolingApiRoot: 'https://qa.apigee-preprod.gcp.ent.corp/ent-agent-tooling/v1',
  agentToolingAfeApiKey: 'pTIuw6pATdU7HVQbsVLTzAi7KyOCElKSW4mpMlmN9mGYgMXo',
  ecpDalAnalyticsEnabled: true,
  ecpDalRoot: 'https://dal.ecp-qa.homesitep2.com/v1',
  mockapi: {
    addDriver: false,
    addVehicle: false,
    additionalInterest: false,
    agentSupport: false,
    billing: false,
    claims: false,
    document: false,
    membership: false,
    payment: false,
    policy: false,
    preferences: false,
    sapiAnalytics: false,
    users: false,
    userAuthn: false,
  },
});
